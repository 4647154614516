/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */


/* Core CSS required for Ionic components to work properly */

@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */

@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */

@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

/* Swiper bundle */

@import 'swiper/css/bundle';

/* Animation */

@import "~animate.css/animate.min.css";

/* Custom font */

@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    src: url('./assets/fonts/inter/Inter-Regular.ttf');
}

@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    src: url('./assets/fonts/inter/Inter-Medium.ttf');
}

@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    src: url('./assets/fonts/inter/Inter-Bold.ttf');
}

@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 900;
    src: url('./assets/fonts/inter/Inter-Black.ttf');
}


/* Additional color variables */

.ion-color-black {
    --ion-color-base: var(--ion-color-darkest);
    --ion-color-base-rgb: var(--ion-color-darkest-rgb);
    --ion-color-contrast: var(--ion-color-dark-contrast);
    --ion-color-contrast-rgb: var(--ion-color-dark-contrast-rgb);
    --ion-color-shade: var(--ion-color-dark-shade);
    --ion-color-tint: var(--ion-color-dark-tint);
}


/* Util */

::selection {
    background: var(--ion-color-primary);
    /* WebKit/Blink Browsers */
}

::-moz-selection {
    background: var(--ion-color-primary);
    /* Gecko Browsers */
}

html,
body {
    --ion-font-family: "Inter" !important;
    font-family: "Inter" !important;
    --ion-background-color: --ion-color-white-shade;
    --ion-background-color-rgb: --ion-color-white-shade;
}

ion-content {
    --background: var(--ion-color-white-shade);
}

h1,
h2,
h3,
h4,
h5,
ion-title,
ion-card-title {
    --ion-font-family: "Inter" !important;
    font-family: "Inter" !important;
}

small {
    font-size: 11px;
}

.ion-margin-start-small {
    margin-left: 4px;
}

.ion-margin-start-medium {
    margin-left: 8px;
}

.ion-margin-start-large {
    margin-left: 20px;
}

.ion-margin-end-small {
    margin-right: 4px;
}

.ion-margin-end-medium {
    margin-right: 8px;
}

.ion-margin-end-large {
    margin-right: 20px;
}

.ion-margin-top-small {
    margin-top: 4px;
}

.ion-margin-top-medium {
    margin-top: 8px;
}

.ion-margin-top-large {
    margin-top: 20px;
}

.ion-margin-bottom-small {
    margin-bottom: 4px;
}

.ion-margin-bottom-medium {
    margin-bottom: 8px;
}

.ion-margin-bottom-large {
    margin-bottom: 20px;
}

.ion-padding-vertical {
    padding: 0 16px;
}

.ion-padding-horizontal {
    padding: 16px 0px;
}

.ion-padding-start-small {
    padding-left: 4px;
}

.ion-padding-start-medium {
    padding-left: 8px;
}

.ion-padding-start-large {
    padding-left: 20px;
}

.ion-padding-end-small {
    padding-right: 4px;
}

.ion-padding-end-medium {
    padding-right: 8px;
}

.ion-padding-end-large {
    padding-right: 20px;
}

.ion-padding-top-small {
    padding-top: 4px;
}

.ion-padding-top-medium {
    padding-top: 8px;
}

.ion-padding-top-large {
    padding-top: 20px;
}

.ion-padding-bottom-small {
    padding-bottom: 4px;
}

.ion-padding-bottom-medium {
    padding-bottom: 8px;
}

.ion-padding-bottom-large {
    padding-bottom: 20px;
}

.ion-no-padding-start {
    --padding-start: 0;
}

.ion-no-padding-end {
    --padding-end: 0;
}

.ion-no-padding-top {
    --padding-top: 0;
}

.ion-no-padding-bottom {
    --padding-bottom: 0;
}

.font-weight-400 {
    font-weight: 400 !important;
}

.font-weight-500 {
    font-weight: 500 !important;
}

.font-weight-600 {
    font-weight: 600 !important;
}

.font-weight-700 {
    font-weight: 700 !important;
}

.font-weight-900 {
    font-weight: 900 !important;
}

.font-size-smallest {
    font-size: 12px !important;
    line-height: 16px !important;
}

.font-size-small {
    font-size: 14px !important;
    line-height: 16px !important;
}

.font-size-medium {
    font-size: 16px !important;
    line-height: 18px !important;
}

.font-size-large {
    font-size: 24px !important;
    line-height: 26px !important;
}

.ion-block {
    display: block;
}

.ion-flex {
    display: flex;
}

.ion-flex-center {
    flex-direction: column;
    justify-content: center;
}

.ion-full-height {
    height: 100%;
}

hr {
    background: var(--ion-color-dark);
    margin: 22px 0;
}

hr.hr-medium {
    margin: 12px 0;
}

p {
    margin: 0;
    padding: 0;
}

.ion-no-border {
    border: none;
    --border-width: 0 !important;
}

ion-back-button {
    --icon-font-size: 24px;
    --icon-margin-start: 3px;
    --icon-margin-end: 6px;
}

.item-detail-icon {
    color: var(--ion-color-medium);
    opacity: 1;
}

ion-backdrop {
    background: var(--ion-color-white-shade);
}


/* Toolbar */

ion-toolbar {
    --background: var(--ion-color-white-shade);
}

.header-collapse-condense ion-toolbar {
    --background: var(--ion-color-white-shade);
}

.header-translucent-ios ion-toolbar {
    --opacity: 1;
}

.header-ios ion-toolbar:last-of-type {
    --border-width: 0;
}


/* Animation */

:root {
    --animate-duration: .25s;
}


/* Loading controller */

.default-loading {
    --background: var(--ion-color-white);
    --spinner-color: var(--ion-color-primary);
    --width: 100% !important;
}

.default-loading .loading-wrapper p {
    margin: 0;
    font-size: 16px;
    color: var(--ion-color-light);
}

.default-loading .loading-wrapper span {
    font-weight: 400;
    margin-top: 6px;
    display: block;
    color: var(--ion-color-medium);
}


/* Buttons */

ion-button {
    --border-radius: 5px;
    font-weight: 500;
    letter-spacing: .005em;
    margin: 0;
    outline: none !important;
}

ion-button.ion-button-small {
    font-size: 12px !important;
}


/* Lists */

.list-custom {
    border-radius: 8px;
}

.list-custom ion-item {
    // --border-color: var(--ion-color-secondary-tint);
}

.list-custom ion-item:first-child {
    border-radius: 8px 8px 0 0;
    --border-radius: 8px 8px 0 0;
}

.list-custom ion-item:last-child {
    --border-width: 0;
    border-radius: 0 0 8px 8px;
    --border-radius: 0 0 8px 8px;
}

.list-custom ion-item:only-child {
    border-radius: 8px;
    --border-radius: 8px;
}

.list-custom ion-item ion-label {
    margin: 16px 0;
}

.list-custom ion-list-header {
    --padding-start: 0;
    padding-left: 20px;
    color: var(--ion-color-medium);
    font-size: 12px;
    font-weight: 600;
    margin-bottom: 6px;
    text-transform: uppercase;
}

ion-list-header {
    margin: 0 0 8px 0;
    padding-left: 20px;
    display: flex;
    align-items: center;
}

ion-list-header ion-label {
    margin: 0;
    padding: 0;
    font-size: 20px;
}

ion-list-header ion-button {
    font-size: 14px;
}

ion-list-header.ion-list-header-small {
    margin: 0;
}

ion-list-header.ion-list-header-small ion-label {
    font-size: 14px;
    font-weight: 500;
    color: var(--ion-color-tertiary);
}

ion-list.item-card-list {
    background: transparent;
    margin: 0 -16px;
}

ion-list.item-card-list ion-item {
    margin: 0 16px 12px 16px;
    --border-radius: 8px;
    box-shadow: 0 4px 10px 0 rgb(0 0 0 / 7%), 0 2px 10px 0 rgb(0 0 0 / 2%);
    --padding-start: 16px;
}

ion-list.item-card-list ion-item ion-label {
    margin: 16px 0;
}

ion-list ion-item ion-label.sc-ion-label-ios-s h3 {
    font-size: 16px;
    font-weight: 500;
}

ion-list ion-item ion-label.sc-ion-label-ios-s p {
    font-size: 14px;
    color: var(--ion-color-tertiary);
}

p.list-description {
    font-size: 12px;
    color: var(--ion-color-medium);
    margin: 6px 0 0 0;
}


/* Avatar */

ion-avatar.ion-avatar-default-icon {
    background: var(--ion-color-secondary-tint);
    box-shadow: 0 4px 10px 0 rgb(0 0 0 / 7%), 0 2px 10px 0 rgb(0 0 0 / 2%);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 22px;
}

ion-avatar.ion-avatar-danger-icon {
    background: rgba(var(--ion-color-danger-rgb), .1);
    box-shadow: 0 4px 10px 0 rgb(0 0 0 / 7%), 0 2px 10px 0 rgb(0 0 0 / 2%);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 22px;
}

ion-avatar.ion-avatar-default-icon ion-icon {
    color: var(--ion-color-white);
    margin-top: -1px;
    font-size: 20px;
}

ion-avatar.ion-avatar-default img {
    padding: 10px;
    border-radius: 0;
}


/* Loading spinner */

.spinner-container {
    width: 100%;
    height: 100%;
    text-align: center;
    position: absolute;
    z-index: 200;
    background-color: var(--ion-color-white);
}


/* Tab bar */

ion-tabs {
    position: relative;
}

ion-tab-bar {
    --background: var(--ion-color-light-shade);
    --border: none;
}

ion-tab-button {
    --color: var(--ion-color-primary-shade);
}

ion-tab-button ion-icon {
    font-size: 20px;
    margin: 3px 0 1px 0;
}

ion-tab-button ion-label {
    font-size: 10px;
}


/* Fab button */

ion-fab.ion-fab-custom {
    margin-inline-start: -32px;
    bottom: 36px;
    background: var(--ion-color-light-shade);
    padding: 8px;
    border-radius: 50%;
}

ion-fab.ion-fab-custom ion-fab-button {
    width: 46px;
    height: 46px;
    --color: var(--ion-color-primary);
    --background: var(--ion-color-secondary);
}


/* Badge */

ion-badge {
    border-radius: 4px;
    font-size: 12px;
    font-weight: 500;
    padding: 6px 6px;
    margin: 0 0;
    display: flex;
    align-items: center;
}

ion-badge ion-icon {
    margin-right: 3px;
}

ion-badge.ion-color-primary {
    color: var(--ion-color-primary);
    background: rgba(var(--ion-color-primary-rgb), .1);
}

ion-badge.ion-color-success {
    color: var(--ion-color-success);
    background: rgba(var(--ion-color-success-rgb), .1);
}

ion-badge.ion-color-danger {
    color: var(--ion-color-danger);
    background: rgba(var(--ion-color-danger-rgb), .1);
}

ion-badge.ion-color-medium {
    color: var(--ion-color-white);
    background: rgba(var(--ion-color-medium-rgb), .25);
}


/* Hero */

.hero {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 30px 0 0 0;
}

.hero ion-avatar {
    text-align: center;
    margin-bottom: 3px;
}

.hero ion-avatar .avatar-icon-placeholder {
    font-size: 42px;
}


/* Forms */

ion-input {
    --placeholder-color: var(--ion-color-medium);
}

form.form-default ion-list.item-card-list ion-item {
    padding: 1px;
}

form.form-default ion-list.item-card-list ion-item.item-has-focus {
    border: 1px solid var(--ion-color-tertiary);
    border-radius: 8px;
    padding: 0;
    box-shadow: 0 2px 5px 0 rgb(126 144 169 / 7%), 0 2px 5px 0 rgb(126 144 169 / 2%);
}

form.form-default ion-list.item-card-list ion-item ion-label {
    margin: 12px 0 -6px 0;
    font-weight: 500;
}

form.form-default ion-list.item-card-list ion-item.item-label-floating ion-input {
    font-size: 16px;
    --padding-top: 0;
    --padding-bottom: 0;
    margin-bottom: 8px;
}

small.error-message-form {
    display: block;
    margin: -6px 16px 12px 16px;
    font-weight: 500;
}


/* Action Sheet */

ion-action-sheet.custom-action-sheet {
    --backdrop-opacity: .8;
}

.custom-action-sheet .action-sheet-group {
    border-radius: 5px;
    background-color: var(--ion-color-primary);
}

.custom-action-sheet .action-sheet-title {
    text-align: left;
    color: var(--ion-color-light);
    padding: 12px 18px;
    font-weight: 500;
    font-size: 14px;
}

.custom-action-sheet .action-sheet-sub-title.sc-ion-action-sheet-ios {
    padding: 0;
    font-weight: 400;
    font-size: 12px;
}

.custom-action-sheet button.action-sheet-button {
    text-align: left;
    font-size: 16px;
    font-weight: 500;
    color: var(--ion-color-white);
}

.custom-action-sheet button.action-sheet-button.action-sheet-cancel {
    color: var(--ion-color-light);
    background: var(--ion-color-secondary);
}

.custom-action-sheet .action-sheet-cancel.sc-ion-action-sheet-ios::after {
    background: transparent;
}

.custom-action-sheet button.action-sheet-button.action-sheet-cancel ion-icon {
    color: var(--ion-color-light);
}

.custom-action-sheet .action-sheet-button-inner {
    text-align: left;
    justify-content: left;
}

.custom-action-sheet .action-sheet-button-inner ion-icon {
    color: var(--ion-color-light);
    width: 28px;
    margin-right: 16px !important;
}


/* Swiper Cards */

swiper.swiper-cards {
    text-align: center;
}

swiper.swiper-cards.swiper {
    width: 100%;
    padding-bottom: 30px;
    margin-bottom: 16px;
}

swiper.swiper-cards .swiper-slide {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0;
}

swiper.swiper-cards .swiper-pagination-bullet {
    background: var(--ion-color-tertiary);
}

swiper.swiper-cards .swiper-pagination-bullet-active {
    background: var(--ion-color-dark);
}


/* CC Template */

.cc-template {
    width: 100%;
    max-width: 350px;
    border-radius: 12px;
    box-shadow: 0 4px 10px 0 rgb(0 0 0 / 7%), 0 2px 10px 0 rgb(0 0 0 / 2%);
    padding: 16px;
    color: var(--ion-color-light);
}

.cc-template-color-1 {
    background-image: linear-gradient(135deg, #F72B2D 0%, #001C6E 100%);
}

.cc-template-color-2 {
    background-image: linear-gradient(135deg, #D71E28 0%, #FFCD41 100%);
}

.cc-bankname {
    text-align: right;
    font-size: 16px;
    font-weight: 500;
}

.cc-number {
    text-align: left;
    font-weight: 500;
    padding: 48px 0 24px 0;
    font-size: 20px;
}

.cc-footer {
    display: flex;
    justify-content: space-between;
    text-align: left;
}

.cc-footer>div {
    display: flex;
}

.cc-expiry-date {
    margin-right: 16px;
}

.cc-expiry-date p {
    font-size: 12px;
    font-weight: 500;
}

.cc-expiry-date span {
    font-size: 14px;
    font-weight: 600;
}

.cc-cvv p {
    font-size: 12px;
    font-weight: 500;
}

.cc-cvv span {
    font-size: 14px;
    font-weight: 600;
}

.cc-logo img {
    width: 50px;
}

.cc-description {
    font-size: 12px;
    color: var(--ion-color-light);
    margin-top: 8px;
    margin-bottom: 8px;
}


/* Alert */

ion-alert.sc-ion-alert-ios-h {
    --backdrop-opacity: .8;
}

.alert-wrapper.sc-ion-alert-ios {
    background: var(--ion-color-primary);
    color: var(--ion-color-white-shade);
    border-radius: 8px;
    width: 100%;
    max-width: calc(100% - 16px);
    padding: 24px 0px 8px 0;
}

.alert-head.sc-ion-alert-ios {
    padding-top: 0;
    padding-bottom: 6px;
    color: var(--ion-color-white-shade);
}

.alert-title.sc-ion-alert-ios {
    margin-top: 0px;
    font-size: 18px;
    font-weight: 600;
    color: var(--ion-color-white-shade);
}

.alert-message.sc-ion-alert-ios {
    font-size: 14px;
    color: var(--ion-color-white-shade);
}

.alert-button.sc-ion-alert-ios.danger {
    color: var(--ion-color-white);
    font-weight: 500;
    border: none;
    background: var(--ion-color-danger);
    margin: 0px 16px;
    border-radius: 3px;
}

.alert-button.sc-ion-alert-ios.alert-button-role-cancel {
    color: var(--ion-color-medium);
    font-weight: 500;
    border: none;
    background: transparent;
    margin: 0px 16px;
    border-radius: 3px;
}

.alert-button.sc-ion-alert-ios:last-child {
    margin-top: 6px !important;
}

.alert-button-group.sc-ion-alert-ios {
    max-width: 75%;
    margin: 0 auto;
}


/* Loading */

.default-loading .loading-wrapper p {
    margin: 0;
    font-size: 16px;
    color: var(--ion-color-white);
}

.default-loading .loading-wrapper span {
    font-weight: 400;
    margin-top: 6px;
    display: block;
    color: var(--ion-color-light-shade);
}

.default-loading {
    --background: var(--ion-color-primary);
    --spinner-color: var(--ion-color-white);
    --width: 100% !important;
    --max-width: 340px;
    --backdrop-opacity: .9;
}


/* Popover */

ion-popover.popover-in-modal {
    --offset-x: -10px;
    --backdrop-opacity: .25;
}

ion-popover.popover-in-modal ion-radio-group ion-item {
    --inner-border-width: 0;
    border-bottom: 1px solid var(--ion-color-light);
    font-size: 14px;
}

ion-popover.popover-in-modal ion-radio-group ion-item:last-child {
    border: none;
}

ion-popover.popover-in-modal ion-list ion-item {
    --inner-border-width: 0;
    --background: var(--ion-color-light);
}


/* Datetime */

ion-datetime {
    --background-rgb: rgb(32 41 52);
    --background: var(--ion-color-secondary);
    border-bottom: 1px solid var(--ion-color-light);
}

ion-datetime .calendar-days-of-week {
    color: green;
}


/* Range */

ion-item.item-range {
    --padding-start: 16px;
    --inner-padding-end: 16px;
    padding-top: 3px;
}

ion-item.range-values {
    font-size: 12px;
    color: var(--ion-color-white);
    --padding-start: 0;
    --inner-padding-end: 0;
    --height: 20px;
    --min-height: 20px;
    padding-bottom: 3px;
}

ion-item.range-values>div {
    width: 63px;
    text-align: center;
    margin: -12px 0 0 0;
}


/* Note */

ion-note.ion-note-small {
    font-size: 14px;
    font-weight: 400;
}


/* Modal */

ion-footer.modal-footer {
    padding: 8px 0;
}


/* Chart */

ion-card.ion-card-chart ion-card-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

ion-card.ion-card-chart ion-card-title {
    font-size: 20px;
    margin-bottom: 3px;
}

ion-card.ion-card-chart ion-card-subtitle {
    font-size: 12px;
    font-weight: 500;
    color: var(--ion-color-tertiary);
    text-transform: none;
}


/* Logo */

.logo-container {
    text-align: center;
    margin-bottom: 40px;
}

h1.logo-placeholder {
    font-weight: 600;
    background: -webkit-linear-gradient(var(--ion-color-dark), var(--ion-color-tertiary));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
    font-size: 36px;
    margin: 0 0 6px 0;
}

.logo-container p {
    color: var(--ion-color-tertiary);
}


/* Searchbar */

ion-searchbar.sc-ion-searchbar-ios-h {
    --cancel-button-color: var(--ion-color-medium);
    --background: var(--ion-color-light-tint);
    --border-radius: 8px;
}


/* Skeleton */

ion-skeleton-text {
    --border-radius: 3px;
}

ion-avatar ion-skeleton-text {
    --border-radius: 50%;
}
