// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/

:root {
    /** primary **/
    --ion-color-primary: #1f2346;
    --ion-color-primary-rgb: 178, 195, 255;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255, 255, 255;
    --ion-color-primary-shade: #5e6ef8;
    --ion-color-primary-tint: #424b9e;
    /** secondary **/
    --ion-color-secondary: #eb1c74;
    --ion-color-secondary-rgb: 255, 0, 98;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255, 255, 255;
    --ion-color-secondary-shade: #e00056;
    --ion-color-secondary-tint: #ff1a72;
    /** tertiary **/
    --ion-color-tertiary: #7E90A9;
    --ion-color-tertiary-rgb: 126, 144, 169;
    --ion-color-tertiary-contrast: #000000;
    --ion-color-tertiary-contrast-rgb: 0, 0, 0;
    --ion-color-tertiary-shade: #6f7f95;
    --ion-color-tertiary-tint: #8b9bb2;
    /** success **/
    --ion-color-success: #2dd36f;
    --ion-color-success-rgb: 45, 211, 111;
    --ion-color-success-contrast: #ffffff;
    --ion-color-success-contrast-rgb: 255, 255, 255;
    --ion-color-success-shade: #28ba62;
    --ion-color-success-tint: #42d77d;
    /** warning **/
    --ion-color-warning: #ffc409;
    --ion-color-warning-rgb: 255, 196, 9;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0, 0, 0;
    --ion-color-warning-shade: #e0ac08;
    --ion-color-warning-tint: #ffca22;
    /** danger **/
    --ion-color-danger: #F47068;
    --ion-color-danger-rgb: 244, 112, 104;
    --ion-color-danger-contrast: #000000;
    --ion-color-danger-contrast-rgb: 0, 0, 0;
    --ion-color-danger-shade: #d7635c;
    --ion-color-danger-tint: #f57e77;
    /** dark **/
    --ion-color-dark: #141A1F;
    --ion-color-dark-rgb: 20, 26, 31;
    --ion-color-dark-contrast: #ffffff;
    --ion-color-dark-contrast-rgb: 255, 255, 255;
    --ion-color-dark-shade: #12171b;
    --ion-color-dark-tint: #2c3135;
    /** medium **/
    --ion-color-medium: #546273;
    --ion-color-medium-rgb: 84, 98, 115;
    --ion-color-medium-contrast: #ffffff;
    --ion-color-medium-contrast-rgb: 255, 255, 255;
    --ion-color-medium-shade: #4a5665;
    --ion-color-medium-tint: #657281;
    /** light **/
    --ion-color-light: #f4f5f8;
    --ion-color-light-rgb: 244, 245, 248;
    --ion-color-light-contrast: #000000;
    --ion-color-light-contrast-rgb: 0, 0, 0;
    --ion-color-light-shade: #d7d8da;
    --ion-color-light-tint: #f5f6f9;
    /** white **/
    --ion-color-white: #ffffff;
    --ion-color-white-rgb: 255, 255, 255;
    --ion-color-white-contrast: #000000;
    --ion-color-white-contrast-rgb: 0, 0, 0;
    --ion-color-white-shade: #e0e0e0;
    --ion-color-white-tint: #ffffff;
}

.ios body {
    --ion-background-color: var(--ion-color-light);
    --ion-background-color-rgb: var(--ion-color-light-rgb);
    --ion-text-color: var(--ion-color-dark);
    --ion-text-color-rgb: var(--ion-color-dark-rgb);
    --ion-item-background: var(--ion-color-light-shade);
    --ion-card-background: var(--ion-color-light-shade);
    // --ion-color-step-50: #000000;
    // --ion-color-step-100: #1a1a1a;
    // --ion-color-step-150: #262626;
    // --ion-color-step-200: #333333;
    // --ion-color-step-250: #404040;
    // --ion-color-step-300: #4d4d4d;
    // --ion-color-step-350: #595959;
    // --ion-color-step-400: #666666;
    // --ion-color-step-450: #737373;
    // --ion-color-step-500: #808080;
    // --ion-color-step-550: #8c8c8c;
    // --ion-color-step-600: #999999;
    // --ion-color-step-650: #a6a6a6;
    // --ion-color-step-700: #b3b3b3;
    // --ion-color-step-750: #bfbfbf;
    // --ion-color-step-800: #cccccc;
    // --ion-color-step-850: #d9d9d9;
    // --ion-color-step-900: #e6e6e6;
    // --ion-color-step-950: #f2f2f2;
    --ion-color-step-950: #000000;
    --ion-color-step-900: #1a1a1a;
    --ion-color-step-850: #262626;
    --ion-color-step-800: #333333;
    --ion-color-step-750: #404040;
    --ion-color-step-700: #4d4d4d;
    --ion-color-step-650: #595959;
    --ion-color-step-600: #666666;
    --ion-color-step-550: #737373;
    --ion-color-step-500: #808080;
    --ion-color-step-450: #8c8c8c;
    --ion-color-step-400: #999999;
    --ion-color-step-350: #a6a6a6;
    --ion-color-step-300: #b3b3b3;
    --ion-color-step-250: #bfbfbf;
    --ion-color-step-200: #cccccc;
    --ion-color-step-150: #d9d9d9;
    --ion-color-step-100: #e6e6e6;
    --ion-color-step-50: #f2f2f2;
}

.ios ion-modal {
    --ion-background-color: --ion-color-light;
    --ion-toolbar-background: transparent;
    --ion-toolbar-border-color: transparent;
}
